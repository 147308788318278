.fileUploadProgress {
  
}

.label {
  font-size: 18px;
  color: #666666;
  font-family: 'Rubik', sans-serif;
  margin-bottom: 15px;

}

.uploadContainer {
  background-color: #F4F4F4;
  border: 1px solid #e0e0e0;
  height: 90px;

  border-radius: 8px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;

}

.fileInfo {
  display: flex;
  justify-content: space-between;
  align-items: center;

}

.fileName {
  font-size: 16px;
  font-weight: 600;
  font-family: 'Open Sans', sans-serif;
margin-top: 11px;
margin-left: 19px;
  color: #232321;
}

.cancelIcon {
  cursor: pointer;
  fill: #d32f2f;
  margin-right: 11px;

}




.progress {
  height: 100%;
  background-color: #00726B;
  border-radius: 8px;
}

.fileSize {
  font-size: 12px;
  font-weight: 500;
  font-family: 'Poppins', sans-serif;
  margin-left: 19px;
  margin-bottom: 5px;
  color: #808080;
}

.cancelContainer{
  display:flex;
  align-items:center;
}

.toastContent{
    display: flex ;
    flex-direction: row ;
    justify-content: space-between;
    align-items: center
}
.toastIcon{
    background-color: #CAFFFC;
    padding: 8px;
    border-radius: 8px;
}
.toastIconError{
    background-color: #FFDBDB;
    padding: 8px;
    border-radius: 8px;
}
.leftContainer{
    display: flex;
    gap: 16px;
    align-items: center;
}
.toastMessage{
    color:rgba(84, 89, 94, 0.6);
    font-weight: 400;
}
.closeButton{
    position: absolute;
    top:10px;
    right:10px
}
.loginContainer {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f3f4f8;
}

.loginBox {
  width: 450px;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-right: 45px;
  padding-left: 45px;
  background-color: #fff;
  border-radius: 20px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  text-align: center;
}

.logo {
  margin-bottom: 10px;
}

.title {
  color: black;
  font-size: 38px;
  font-weight: 500;
  font-family: "Rubik", sans-serif;
}
.row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-top: 10px;
}

.underTitle {
  text-align: left;
  font-family: "Rubik", sans-serif;

  color: #666666;
  margin-bottom: 12px;
  margin-top: 12px;
}
.underTitlePasword{
  text-align: left;
  font-family: "Rubik", sans-serif;

  color: #666666;
  margin-bottom: 0px;
  margin-top: 12px;
}
.loginInput {
  margin-bottom: 16px;
  width: 100%;
}

.loginOptions {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 16px;
}

.rememberMe {
  font-weight: bold;
}

.forgotPassword {
  color: #00726b;
  text-decoration: underline;
  cursor: pointer;
  font-weight: bold;
}

.loginButton {
  background-color: #d32f2f;
  color: #fff;
  font-size: 16px;
  padding: 10px 0;
  text-transform: none;
}

.loginButton:hover {
  background-color: #b71c1c;
}

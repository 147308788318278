.container {
  background-color: #ffffff;
  border-radius: 16px;
  padding-left: 25px;
  
  padding-right: 25px;
margin-top: 45px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 24px;
  padding-bottom: 20px;
  padding-right: 24px;
  font-family: 'Public Sans', sans-serif;
  font-weight: 600;
  font-size: 22px;
  cursor: pointer;

}

.line {
  border: 1px solid #E3E3E3;
  width: 100%; 
margin-bottom: 30px;
  padding: 0; 
}


.moreOptions {
  background: none;
  border: none;
  cursor: pointer;
  padding: 5px;
}

.dropdownContainer {
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-bottom: 20px;
}

.dropdown {
  position: relative;
  
}

.dropdownClosed {
  height: 42px;
  overflow: hidden; 
  transition: height 0.3s ease;
}

.dropdownOpen {
  height: auto; 
  transition: height 0.3s ease; 
}


.dropdownHeader {
  width: 100%;
  display: flex;
  align-items: center;
  cursor: pointer;
  padding-right:17px;
  padding-left:17px;
  font-family: 'Rubik', sans-serif;
  height:56px;
  border: 1px solid #66666659;
  border-radius: 5px;
  background-color: #fff;
}

.dropdownInnerContainer{
  display: flex;
  align-items: center;
  flex:1
}

.inputSearch{
  border:none;
  outline:none;
  height: 100%;
  flex:1
}

.arrow {
  transition: transform 0.2s;
}


.dropdownList {
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  background-color: #ffffff;
  border: 1px solid #e0e0e0;
  border-radius: 5px;
  margin-top: 5px;
  z-index: 10;
  max-height: 250px;
  overflow-y: auto;
}

.dropdownItem {
  padding: 10px;
  cursor: pointer;
  border-bottom: 1px solid #e0e0e0;
}

.dropdownItem:last-child {
  border-bottom: none;
}

.dropdownItem:hover {
  background-color: #f0f0f0;
}

.dropdownTitle{
  font-family: 'Rubik', sans-serif;
  font-weight: 400;
  font-size: 18px;
  
  color: #666666;
}


.chartContainer {
  margin-top: 45px;
  min-height: 250px;  
  display: flex;
  justify-content: center;
  align-items: center;
}

.iconContainer{
  display: flex;  
  align-items: center;
}

.icons {
  display: flex;
  gap: 5px;
  align-items: center;
  margin-bottom: 10px;
  
}

.icon:hover{
  cursor: pointer;
}


.dropdownHeader .arrow {
  transition: transform 0.2s;
}

.dropdownHeader .arrowDown {
  transform: rotate(180deg);
}

.addSchoolButton {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
  margin-top: 10px;
  background-color: #f0f0f0;
  border: 1px solid #d0d0d0;
  border-radius: 5px;
  cursor: pointer;
}


.accessCodeLabel {
  font-weight: 400;
  font-size: 14px;
  color:#00726B;
  font-family: 'Rubik', sans-serif;


}

.yearDropdown {
  position: relative;
  border: 1px solid #E2E2E2;
  margin-right: 15px;
  border-radius: 6px;
  padding-right: 12px;
  padding-left: 12px;
  padding-top: 6px;
  padding-bottom: 6px;
}

.yearHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  color:#606060;
}

.yearList {
  position: absolute;
  top: 100%;
  left: 0;
  background-color: white;
  border: 1px solid #e0e0e0;
  border-radius: 4px;
  margin-top: 4px;
  padding: 8px;
  max-height: 150px;
  overflow-y: auto;
  z-index: 10;
}

.yearItem {
  padding: 8px;
  cursor: pointer;
}

.yearItem:hover {
  background-color: #f0f0f0;
}

.smallArrow {
  margin-left: 8px;
}


.chartControls {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 16px 0;
}


.legend {
  display: flex;
  flex-wrap: wrap;
  gap: 40px;
  margin-top: 16px;
}

.legendItem {
  display: flex;
  align-items: center;
  font-weight: 600;
  font-size: 12px;
  color:#222222;
  gap: 10px;
  padding-bottom: 30px;

}

.colorCircle {
  width: 12px;
  height: 12px;
  border-radius: 50%;
}
.detailsIcon {
  margin-right: 35px;  

}

.searchInput{
  flex:1;
  border:none;
  outline: none;
  height: 100%;
}

.chartContainer{
  width: 100%;
}

.chart{
  display: flex;
  align-items: center;
  justify-content:center;
  min-height: 250px;
}

.inputContainer{
  display: flex;
  justify-content: center;
  align-items: center;
  
  width: 100%;
}


.content {
    margin-top: 50px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .content2{
    margin-bottom: 34px;
    margin-top: 34px;

    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  
  .content3{
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .spacer {
    width: 487px;
  }
  .spacer2 {
    width: 553px;
  }
  .spacer3 {
    width: 400px;
  }
  .paginationContainer{
    margin-top: 37px;

  }
  .buttonSpacer {
    width: 8px;
  }
  .link {
    text-decoration: none;
  }
  
  .bookCover{
   width: 45px; 
   height: 64px;
   border-radius:8px
  }
  
  input[type="number"]::-webkit-outer-spin-button,
  input[type="number"]::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Hide spin buttons in Firefox */
  input[type="number"] {
    -moz-appearance: textfield;
  }
  
  .label {
    font-size: 16px;
    color: #808080;
    margin-bottom: 10px;
  }
  
  .counterContainer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border: 1px solid #ccc;
    border-radius: 4px;
    height: 56px;
  }
  
  .icon {
    cursor: pointer;
    fill: #006d68;
    margin-left: 55px;
    margin-right: 55px;
  }
  
  .input {
    border: none;
    text-align: center;
    font-size: 18px;
    font-weight: 400;
    font-family: 'Rubik', sans-serif;
    width: 80px;
    outline: none;
    background-color: transparent ;
    flex:1
  }
  
  .button{
    height: 100%;
  }
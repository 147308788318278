.background {
    background-color: white;
    padding: 1px 27px;
    padding-bottom: 30px;
    margin-top: 35px;
    margin-right: 35px;
    margin-left: 8px;
    border-radius: 16px;
  }
  
  .Title {
    font-weight: 600;
    font-size: 20px;
    font-family: 'Public Sans', sans-serif;
    margin-bottom: 30px;
  }
  
  .form {
    display: flex;
    flex-direction: column;
  }
  
  .row {
    display: flex;
    justify-content: space-between;
    margin-bottom: 30px;
  }
  
  .field {
    flex: 1;
    margin-right: 33px;
  }
  
  .field:last-child {
    margin-right: 0;
  }
  .textField{
    width: 100%;
  }
  
  .phoneContainer {
    display: flex;
  }
  
  .phoneCode {
    width: 13%;
    margin-right: 0px;
  border-radius: 5px 0 0 5px; 
  }
  
  .phoneNumber {
    width: 80%;
    border-radius: 0 5px 5px 0; 

  }
  
  .fullWidth {
    width: 100%;
  }
  
  .buttonContainer {
    display: flex;
    justify-content: flex-end;
    gap:32px
  }
  

  
  .DeleteButton{
    color:#BE1E2D;
    font-family: 'Rubik', sans-serif;
    font-size: 14px;
    font-weight: 500;
    background-color: #fff; 
    border: 0px solid #fff;

 }

 .ArchiveButton{
   color: #00726B;
   font-family: 'Rubik', sans-serif;
   font-size: 14px;
   font-weight: 500;
   background-color: #fff; 
   border: 2px solid #00726B;
   padding:8px, 16px, 8px, 16px;
   border-radius: 8px;
   width:141px;
   height:48px;
 }

 .AddButton{
    color: #fff;
   font-family: 'Rubik', sans-serif;
   font-size: 14px;
   font-weight: 500;
   background-color: #00726B; 
   border: 2px solid #00726B;

   padding:8px, 16px, 8px, 16px;
   border-radius: 8px;
   width:141px;
   height:48px;
   display: flex;
   justify-content: center;
   align-items: center;
 }
 .arrowIcon {
     left: 30px;
    top: 50%;
    flex:1;
    transform: translateY(-50%);
    
  }
    
  .content3{
    display: flex;
    align-items: center;
  }
  

  .desctextField{
    width: 100%;
    height: 159px;
    font-size: 18px;
    font-family: 'poppins', sans-serif;
    font-weight: 500;
    padding: 10px;
    border: 1px solid #66666659;
    border-radius: 5px;
    box-sizing: border-box;
  }
  select {
    padding-right: 10px;  
  }
  
  label {
    display: block;
    margin-bottom: 10px;
    color: #666666;
    font-size: 18px;
    font-weight: 400;
    font-family: 'Rubik', sans-serif;
  }
  
  textarea {
    resize: vertical;

  }

  .link{
    text-decoration: none;
    margin-left: auto;
    margin-right: 30px;
    margin-top: 45px;

  }
  .Title{
    margin-top: 52px;
    font-size: 20px;
    font-weight: 600;
    font-family: 'public sans', sans-serif;
  }

  .batchesContainer{
    display: flex;
    justify-content: space-between;
  }

  
  .tableHeader{
    display: flex;
    justify-content: space-between;
  }
  .headerText{
    font-weight: 600;  
    font-size:20px;

  }
  .addButton{
    background-color: #00726B;
    color:white;
    display: flex;
    justify-content: space-between;
    align-items: center;  
    padding: 10px 26px 10px 32px;
    gap: 8px;
    border-radius: 8px;
  }

  .phoneContainer {
    display: flex;
    width: 100%;
  }
  
  .phoneCode {
    width: 13%;
    margin-right: 0px;
    border-radius: 5px 0 0 5px; 
    border-right:none
  }
  .phoneCode:focus{
    outline: none;
  }
  
  .phoneNumber {
    width: 100% !important;
    border-radius: 0 5px 5px 0; 
    flex:1
  }
  .phoneNumberInputContainer{
    display: flex;
  }
  
  
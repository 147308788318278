.header{
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.topPagination{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 34px;
}

.table{
    margin-top: 16px;
    background-color: #FFFFFF;
    border-radius: 16px;
    padding: 24px 16px;
    margin-bottom: 50px;
}

.tableHeader{
    display: flex;
    gap: 16px;
    padding: 16px 0;
    padding-left: 8px;
    background-color:rgba(248, 249, 250, 1);
    border-bottom: 1px solid rgba(35, 35, 33, 0.2);
}

.tableHeaderCell{
    flex:1;
    display:flex;
    align-items: center;
    gap:10px;
    

}
.tableHeaderCell:hover{
    cursor: pointer;
}

.tableRow{
    display: flex;
    gap: 16px;
    padding: 19px 0px 24px 0;
    padding-left:8px;
    align-items: center;
    border-bottom: 1px solid rgba(35, 35, 33, 0.2);
}
.tableCell{
    flex:1;
    overflow: hidden;
    text-overflow: ellipsis;
}

.loading{
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 16px 0;
}
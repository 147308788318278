.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0px 25px 0px 25px;
  background-color: #ffffff;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1); 
  position: fixed;
  width: calc(100% - 290px); 
  height: 100px;
  z-index: 999;
  font-family: Public Sans, sans-serif;

}

.pageTitle {
  font-size: 24px;
  font-weight: 700;

 }

.userInfo {
  display: flex;
  flex-direction: column; 
  padding-right: 30px;
}

.userRow {
  display: flex;
  align-items: center; 
}

.username {
  margin-right: 10px; 
  font-weight: 600;
  font-size: 14px;
}

.onlineStatus {
  display: flex;
  align-items: center;
  font-size: 14px;
}

.onlineDot {
  display: inline-block;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  margin-right: 5px;
}

.offlineDot {
  display: inline-block;
  width: 10px;
  height: 10px;
  background-color: grey;
  border-radius: 50%;
  margin-right: 5px;
}

.onlineIcon {
/* margin-bottom: 4px; */
margin-right: 20px;
}

.role {
  color: #A0A0A0;
  font-size: 14px;
font-weight: 400;
  margin-top: 5px; 
  /* text-align: center; */

}

.background{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.25);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
}

.container{
    position: relative;
    background-color: #FFFFFF;
    width: 750px;
    height: 233px;
    display: flex ;
    flex-direction: column !important;
    justify-content: space-between;
    padding: 36px 40px;
}

.header{
    font-size: 20px;
    color:#54595E;
    font-weight: 500;
    margin-bottom: 8px;
}

.parag{
    font-size: 14px;
    font-weight: 400;
    color:rgba(84, 89, 94, 0.6)
}

.buttonContainer{
    display: flex;
    justify-content: flex-end;
    margin-top: 24px;
    gap:16px;
    width:100%
}

.buttonContainer>button{
    padding: 13px 0;
    width: 180px;
    border-radius: 8px;
    margin:0 !important;
    font-weight: 500;
}


.confirmButton{
    background-color: #00726B;
    color: #F5F5F5;
}

.confirmButton>p{
    display: inline;
}

.confirmDelButton{
    background-color: #BE1E2D;
    color: #F5F5F5;
}

.cancelButton{
    border:1px solid #4F4F4F
}

.CloseButton{
    position: absolute !important;
    right: 10px;
    top:10px
}
.table {
    width: 100%;
    border-collapse: collapse;
}


.lastCol{
    display: flex;  
    justify-content: space-between;
    align-items: center;    
}

.tableHeader {
    display: grid;
    column-gap: 16px;
    align-items: center;
    background-color: #F8F9FA;
    border-bottom: 1px solid #dfe3e8;
    padding-left:16px
}

.tableHeaderCell {
    display: flex;
    color: #232321CC;
    font-weight: 500;
    font-size: 14px;
    padding: 20px 0px;
    cursor: pointer;
    font-family: Rubik, sans-serif;
    flex: 1;
    white-space: nowrap;
}

.actions {
    text-align: center;
    font-weight: 500;
    color: #232321cc;
    position: relative;
}



.tableRow {
    display: flex;
    gap:16px;
    align-items: center;
    font-size: 12px;
    padding: 20px 0px; 
    padding-left:16px ;
    margin-right: 10px;
    font-weight: 600;
    font-family: 'Open Sans', sans-serif;
}

.tableRow:hover{
    background-color: #f9f9f9;
    cursor: pointer;
}

.tableCell {
    flex: 1;
    color: black;
    margin-right: 5px;
    white-space: nowrap;

}

.tableCell:not(:last-child){
    overflow: hidden;
    text-overflow: ellipsis;
}


.rowSeparator {
    border: none;
    border-top: 0.5px solid #23232133;
    width: 100%;
}

.arrowIcon {
    margin-right: 10px;
}
.detailsIcon{
    cursor: pointer;
    width: 30px;
    position: relative;
    right: -30px;
}
/* Dropdown Menu Styles */
.dropdownMenu {
    position: absolute;
    width: 108px;
    top: 40%;
    right:-10px;
    background-color: #fff;
    border: 1px solid #e2e2e2;
    border-radius: 10px;
    box-shadow: 0 1px 4px rgba(0, 0, 0, 0.1);
    font-size: 12px;
    font-weight: 400;
    align-items: left;
    text-align: left;
    color: #212121;
    z-index: 100;
    font-family: Nunito, sans-serif;
    display: flex;
    flex-direction: column;
}

.dropdownItem {
    padding: 10px 20px;
    cursor: pointer;
    white-space: nowrap;
}

.dropdownItem:hover {
    background-color: #f0f0f0;
}

.delete {
    color: #dd112d;
}

.container {
    background-color: #ffffff;
    border-radius: 16px;
    border: 1px solid #23232133;
    padding: 16px;
    margin-bottom: 40px;
    
}

.card {
    background-color: #ffffff;
    border-radius: 16px;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.07); 
    padding: 25px;
    width: 32%; 
    height: 130px; 
    position: relative; 
    font-family: Open Sans, sans-serif;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}
  
.cardHeader {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
  
.number {
    font-size: 30px;
    font-weight: 700;
    font-family: Public Sans, sans-serif;
    color: #23272E;
}
  
.title {
    font-size: 14px;
    font-weight: 500;
    text-align: left;
    color: #333333;
    font-family: Public Sans, sans-serif;
}

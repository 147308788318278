.content {
  margin-top: 50px;
  display: flex;
  align-items: center;
  justify-content: space-between  ;
  margin-bottom: 34px;
}
.content2{
  display: flex;
  margin-bottom: 35px;
justify-content: space-between;
  align-items: center;
}

.content3{
  display: flex;
  align-items: center;
  justify-content: center;
}
.spacer {
  width: 487px;
}
.spacer2 {
  width: 553px;
}
.spacer3 {
  width: 650px;
}

.buttonSpacer {
  width: 8px;
}
.link {
  text-decoration: none;
}


.field{
  margin:16px 0;
}

.field:last-of-type{
  margin-bottom:0;
}
.textField{
  width:100%;

}

.textFieldLarge{
  height: 200px;
  width: 100%; 
  text-align: start;
  resize: none;
}

.closeBtn{
  position: absolute;
  right: 20px;
  font-weight: bolder;
  background-color: black;
  width: 20px;
  height:20px;
  padding:4px;
  border-radius: 100px;
  display: flex;
  align-items: center;
  justify-content: center;  
}
.closeBtn:hover{
  background-color: grey;
}
.btnsvg:hover{
  fill:black
}

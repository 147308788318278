  .content {
    margin-top: 50px;
    display: flex;
    align-items: center;
    justify-content: space-between  ;
    margin-bottom: 34px;
  }
  .content2{
    display: flex;
    margin-bottom: 35px;
justify-content: space-between;
    align-items: center;
  }
  
  .content3{
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .spacer {
    width: 487px;
  }
  .spacer2 {
    width: 553px;
  }
  .spacer3 {
    width: 650px;
  }
  
  .buttonSpacer {
    width: 8px;
  }
  .link {
    text-decoration: none;
  }
  
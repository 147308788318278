.pagesCounter {
    position: relative;
    flex:1; 
  }
  
  .label {
    font-size: 16px;
    color: #808080;
    margin-bottom: 10px;
  }
  
  .counterContainer {
    display: flex;
    align-items: center;  
    border: 1px solid #ccc;
    border-radius: 4px;
    height: 56px;
    
  }
  
  .icon {
    cursor: pointer;
    fill: #006d68;
  }
  
  .input {
    border: none;
    text-align: center;
    font-size: 18px;
    font-weight: 400;
    font-family: 'Rubik', sans-serif;
    width: 80px;
    outline: none;
  }
  .smallInput{
    flex:1;
    height: 50px;
    border: none;
    text-align: center;
    font-size: 18px;
    font-weight: 400;
    font-family: 'Rubik', sans-serif;
    width: 80px;
    outline: none;
  }
  .button{
    width: 100px;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
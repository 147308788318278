.radioGroup {
    display: flex;
    flex-direction: row;
    gap:130px;
    
  }
  
  .radioLabel {
    display: flex;
    align-items: center;
    cursor: pointer;
    font-size: 18px;
    color: #292929;
    margin-bottom: 8px;
  }
  
  .radioInput {
    display: none;
  }
  
  .customRadio {
    width: 16px;
    height: 16px;
    border-radius: 50%;
    border: 1px solid #000;
    margin-right: 8px;
    display: inline-block;
    position: relative;
  }
  
  .radioInput:checked + .customRadio::after {
    content: '';
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background-color: #00726B;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  
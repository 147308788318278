.background {
  background-color: white;
  padding: 44px 27px;
  margin-top: 35px;
  margin-right: 35px;
  margin-left: 8px;
  border-radius: 16px;
}
.fileButton{
  background-color: #00726B; 
  color: #FFFFFF;
  width: 130px;
  height: 36px;
  border-radius: 5px;
  font-weight: 500;
  font-size: 12px;
  font-family: 'Rubik', sans-serif;
}

.Title {
  font-weight: 600;
  font-size: 20px;
  font-family: 'Public Sans', sans-serif;
  margin-bottom: 30px;
}
.TitleUpload{
  margin-bottom: 16px;
  color:#181818;
  font-family: sans-serif;
  font-weight: 600;
  font-size: 20px;
  line-height: 23.5px;
}

.form {
  display: flex;
  flex-direction: column;
  
}

.uploadContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.fieldsContainer {
  width: 100%;
  display: flex;
  gap:32px;
  flex-direction: column;
  margin-left: 24.48px;
  margin-top: 36px;
}

.fieldInnerContainer{

}

.textField, .desctextField {
  width: 100%;
  height:56px;
  font-family: 'Poppins', sans-serif;
  padding: 10px;
  border: 1px solid #66666659;
  border-radius: 5px;
  box-sizing: border-box;

}

.desctextField {
  height:115.12px;
}

.BookTitleField{
  width: 100%;
  font-size: 16px;
  height:55px;
}
label {
  display: block;
  margin-bottom: 10px;
  color: #666666;
  font-size: 18px;
  font-weight: 400;
  font-family: 'Rubik', sans-serif;
}

.buttonContainer {
  display: flex;
  justify-content: flex-end;
  gap:32px;
  align-self: flex-end;
}



.DeleteButton {
  color: #BE1E2D;
  font-family: 'Rubik', sans-serif;
  font-size: 14px;
  font-weight: 500;
  background-color: #fff;
  border: 0px solid #fff;
}

.ArchiveButton {
  color: #00726B;
  font-family: 'Rubik', sans-serif;
  font-size: 14px;
  font-weight: 500;
  background-color: #fff;
  border: 2px solid #00726B;
  padding: 8px 16px;
  border-radius: 8px;
  width: 141px;
  height: 48px;
}

.AddButton {
  color: #fff;
  font-family: 'Rubik', sans-serif;
  font-size: 14px;
  font-weight: 500;
  background-color: #00726B;
  border: 2px solid #00726B;
  padding: 8px 16px;
  border-radius: 8px;
  width: 141px;
  height: 48px;
  display:flex;
  justify-content: center;
  align-items: center;
}
.row {
  display: flex;
  justify-content: space-between;
  gap:30px;
  margin-bottom:30px;
}

  .field {
    flex: 1;
  }
.field:last-child {
  margin-right: 0;
}

.authorsList{
  background-color: grey;
}

.bookInputContainer{
  border:1px dashed #232321;
  display:flex;
  align-items:center;
  justify-content:center;
  flex:1;
  height: 90px;
  border-radius:8px
}

.upladingContainer{
  display: flex;
  flex-direction: column;
  gap: 16px;
}
.progressBar {
  width: 349px;
  height: 4px;
  background-color: #B8D5E2;
  border-radius: 8px;
  overflow: hidden;


}
.progress {
  height: 100%;
  background-color: #00726B;
  border-radius: 8px;
}
.fileSize {
  font-size: 12px;
  font-weight: 500;
  font-family: 'Poppins', sans-serif;

  color:grey; 
}

.progressContainer{
  display: flex;
  gap: 16px;
  flex-direction: row;
   padding: 10px 0;
  align-items: center;
}
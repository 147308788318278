.ModalOverlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 100;
    
  }
  
  .ModalContent {
    background: white;
    border-radius: 10px;
   
    width: 750px;
    height: 377px;
    position: relative;
  }
  
  .ModalHeader {
    display: flex;
    /* justify-content: center; */
    margin-left: 40px;
  }
  
  .ModalTitle {
    font-size: 20px;
    margin-top: 46px;
    font-weight: 500;
    color: #54595E;
    font-family: 'Rubik', sans-serif;
  }
  
  .CloseButton {
    position: absolute;
    top: 10px;
    right: 10px;
    background: none;
    border: none;
    cursor: pointer;
  }
  
  .CloseIcon {
    width: 24px;
    height: 24px;
  }
  
  .ModalBody {
    margin-right: 40px;
    margin-left: 40px;
  }
  
  .ModalSubtitle {
    font-size: 14px;
    color: #666;
    margin-bottom: 40px;
    margin-top: 8px;
    font-family: 'Rubik', sans-serif;

  }

  
  .DeviceCountLabel {
    display: block;
    margin-bottom: 5px;
  }
  
  .DeviceCountInput {
    width: 670px;
    padding: 10px;
    border: 1px solid #66666659;
    border-radius: 5px;
    margin-bottom: 73px;
    font-family: 'Rubik', sans-serif;

  }
  
  .ModalFooter {
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    padding-right: 40px;
  }
  
  .CancelButton {
    background: none;
    border: 1px solid #4F4F4F;
    padding: 10px 20px;
    border-radius: 8px;
    cursor: pointer;
    width: 180px;
    height: 44px;
    font-size: 14px;
    
    font-weight: 500;
  }
  
  .SaveButton {
    background: #00726B;
    border: none;
    color: white;
    padding: 10px 20px;
    border-radius: 8px;
    cursor: pointer;
    width: 180px;
    height: 44px;
    font-size: 14px;
    font-weight: 500;
  }
  
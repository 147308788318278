.background{
    position: fixed ;
    display: flex;
    align-items: center !important;
    justify-content: center;
    top:0;
    bottom: 0   ;
    left: 0;
    right: 0;
    z-index: 100;
    background-color: rgba(0, 0, 0, 0.25);  
}
.body{
    position:relative ;
    max-height: 90vh; 
    overflow-y: auto;
    scrollbar-gutter:none;
    background-color: #FFFFFF;
    border-radius: 8px;
    width: 1000px;
    padding: 40px 46px;
}

 .body::-webkit-scrollbar {
    display: none; 
}
  
.body {
    -ms-overflow-style: none;  
    scrollbar-width: none;  
}

.headerContainer{
    margin-bottom: 40px;
}
.header{
    font-weight: 500;
    font-size: 25px;
    color: #54595E;
}
.headersText{
    font-weight: 400;
    font-size: 18px;
    color:rgba(84, 89, 94, 0.6);
}
.form{
    display: flex;
    flex-direction: column ;
    gap:40px
}
.radioButtonInput{
    display: flex;
    align-items: center;
    gap: 6px;
}
.row{
    display: flex;
    gap:40px
}
.field{
    flex:1
}
.buttonContainer{
    display: flex;
    gap:16px;
    align-items: center;
    justify-content: flex-end;
    margin-top:40px;
}
.saveButton{
    background-color: #00726B;
    color:white;
    border-radius: 8px;
    padding: 14px 57px 14px 57px;
    
}

.cancelButton{
    border: 1px solid #4F4F4F;
    padding: 14px 57px 14px 57px;
    border-radius: 8px;
}

.radioButtonContainer{
    
    display: flex;
    gap: 40px;
}

.xButton{
    position: absolute;
    right: 34px;
    top:34px
}
.TableContainer {
    padding: 20px;
    border: 1px solid #C9C9C9;
    border-radius: 16px;
    margin-bottom: 50px;

  }
  
  .Table {
    
    border-collapse: collapse;
    
  }
  
  .Table thead {
    background-color: #f5f5f5;

  }
  
  .Table th, .Table td {
    text-align: left;
    padding: 10px;
    border-bottom: 1px solid #ddd;
  }

  .Table td{
    font-size: 12px;
    font-family: 'Open Sans', sans-serif;
    font-weight: 600;
    
  }
  
  .HeaderCell {
    display: flex;
    align-items: center;
    justify-content: left;
    font-size: 14px;
    font-family: 'Rubik', sans-serif;
    font-weight: 500;
    padding:10px 0;
  }
  .HeaderCell:hover{
    cursor: pointer;
  }
  .ArrowIcon {
    margin-right: 10px;
  }
  
  .PaginationContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 20px;
  }
  
  .ShowingInfo {
    display: flex;
    align-items: center;
  }
  
  .PageSizeSelect {
    margin: 0 10px;
  }
  
  .Pagination {
    display: flex;
    gap: 5px;
  }
  
  .PageNumber {
    padding: 5px 10px;
    border: 1px solid #ddd;
    border-radius: 5px;
    cursor: pointer;
  }
  .lastCol{
    display: flex;
    justify-content: space-between;
  }

  .tableRow{
    display: flex;
    padding: 16px 0 16px 8px;
    gap:16px
  }

  .field{
    position: relative;
    flex:1;
    align-items: center;
    justify-content: left;
    display: flex;
    
  }
 
  .field:not(:last-of-type){
    white-space: nowrap;
    overflow: hidden;
    text-overflow:ellipsis;
  }

 
  .fieldText{
    white-space: nowrap;
    overflow: hidden;
    text-overflow:ellipsis;
  }

  .headerRow{
    display: flex;  
    background-color: #F8F9FA;
    padding: 16px 0 16px 8px;
    gap:16px;
    align-items: center;
  }

  .headerFeild{
    flex:1
  }

  .book-cover-image{
    width:  49px;
    height: 64px;
    border-radius: 8px;
  }

  .actions{
    position: absolute;
    right: -8px;
    width: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }

  .dropdownMenu{
    position: absolute;
    top:30%;
    right: 16px;
    z-index: 100;
    background-color: #FFFFFF;
    border-radius: 10px;
    border: 0.5px solid #E2E2E2;
  }

  .dropdownItem{
    color: #DD112D;
    padding: 10px  57px 10px 15px ;
  }

  .dropdownItem:hover{
    background-color: #E2E2E2;
   }
.uploadBox {
    border: 2px dashed #b6b6b6;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 192.52px;
    min-height: 244px;
    text-align: center;
    margin-bottom: 47px;
    padding:8px;
    gap:8px
}

.uploadButton {
    padding: 7px 34px 7px 34px !important;
    background-color: #00726B; 
    color: #FFFFFF;
    border-radius: 5px;
    font-weight: 500;
    font-size: 12px;
    font-family: 'Rubik', sans-serif;
}

.Title {
    font-weight: 400;
    font-size: 18px;
    font-family: 'Rubik', sans-serif;
    margin-bottom: 10px;
    margin-top: 37px;
    color: #666666;
  
    
}

.text {
    font-weight: 600;
    font-size: 12px;
    font-family: 'Open Sans', sans-serif;
    color: #70706E;
    margin-bottom: 15px;
    margin-top: 14.25px;
}

.previewImage {
    flex:1;
    max-width: 100%;     
    max-height: 100%;     
    object-fit: contain;   
    border-radius: 8px;  
}
.container {
    background-color: #ffffff;
    border-radius: 16px;
    margin-top: 35px;
    padding: 16px;
    border:1px solid #C9C9C9
}

.table {
    width: 100%;
    border-collapse: collapse;
}

.tableHeader {
    display: flex;
    align-items: center;
    gap:16px;
    padding: 16px 0;
    padding-left:8px;
    background-color: #f8f9fa;
    border-bottom: 1px solid rgba(35, 35, 33, 0.2);
}

.tableHeaderCell {
    color: rgba(35, 35, 33, 0.8);
    font-weight: 500;
    font-size: 14px;
    align-items: center;
    cursor: pointer;
    font-family: Rubik, sans-serif;
    flex: 1;
    white-space: nowrap;
    display: flex;
}

.actions {
    text-align: center;
    font-weight: 500;
    color: #232321cc;
    position: relative;
}

 

.tableRow {
    display: flex;
    gap:16px;
    align-items: center;
    padding: 16px 0; 
    padding-left:8px;
    font-weight: 600;
    font-family: 'Open Sans', sans-serif;
    border-bottom:  0.5px solid #23232133;
}
.tableRow:hover{
    background-color: #f9f9f9;
}
.tableCell:nth-child(2)>img{
    width: 50px;
    height: 64px;
    border-radius: 5px;
    object-fit: cover;
    margin-right: 10px;
}


.tableCell {
    flex: 1;
    white-space: wrap; 
    font-size: 12px; 
}

.tableCell:last-child{
    display: flex; 
    justify-content: space-between;
    overflow:visible; 
}

.detailsIcon {
    cursor: pointer;
    fill: #4a90e2;
}

.arrowIcon {
    margin-right: 10px; 
}

/* Dropdown Menu Styles */
.dropdownMenu {
    position: absolute;
    width: 108px;
    top: 40%;
    right: 8px;
    background-color: #fff;
    border: 1px solid #E2E2E2;
    border-radius: 10px;
    box-shadow: 0 1px 4px rgba(0, 0, 0, 0.1);
    font-size: 12px;
    font-weight: 400;
    align-items: left;
    text-align: left;
    color:#212121;
    z-index: 100;
    font-family: Nunito, sans-serif;
    display: flex;
    flex-direction: column;
}
.dropdownItem:first-child { 
    padding-top: 12px;
}


.dropdownItem {
    padding: 6px 16px;
    cursor: pointer;
   
    white-space: nowrap;
}

.dropdownItem:hover {
    background-color: #f0f0f0;
}

.delete {
    color: #DD112D;
}

.detailsContainer{
    position: relative;
}
.container{
    display: flex;
    border: 1px solid rgba(102, 102, 102, 0.35);
    min-height: 117px;
    margin-bottom: 30px;
    padding: 20px 24px ;
    align-items: flex-start;
    flex-wrap: wrap;
    cursor: pointer;
    border-radius:5px
}

.authorsList{
    display: flex;
    gap: 16px;
    width: fit-content;
}

.author{
    background: #D9D9D9;
    max-height: fit-content;
    padding: 4px 16px 3px 8px ;
    display: flex;
    gap:16px;
    align-items: center;
    font-weight: 600;
    font-size: 13px;
    border-radius: 5px;
    
}

.authorsIpnut{
    flex:1  ;
    border:none;
    padding: 0;
    max-height:25px;
}
.authorsIpnut:focus{
    outline: none;
}
    
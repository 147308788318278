.content {
  margin-top: 50px;
  display: flex;
  align-items: center;
  justify-content: space-between ;
}
.content2{
  display: flex;
  margin-bottom: 35px;
  margin-top: 37px;
  align-items: center;
  justify-content: space-between;
}

.content3{
  display: flex;
  align-items: center;
  justify-content: center;
}

.buttonSpacer {
  width: 8px;
}
.link {
  text-decoration: none;
}

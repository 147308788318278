.App {
  display: flex;
  height: 100vh;
}


body {
  background-color: #f0f0f0;
}


.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.main-container {
  display: flex;
  flex-direction: row;
  width: 100%;
}

.content-container {
  display: flex;
  flex-direction: column;
  width: 100%; 
  margin-left: 290px; 
  overflow-y: auto;
}

.content {
  padding: 44px 30px;
  flex: 1;
}

.header { 
  position: relative;
  padding-bottom: 100px;
}

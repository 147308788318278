.dropdownSelector {
  position: relative;
  flex:1
}

.label {
  font-size: 18px;
  font-weight: 400;
  font-family: 'Rubik', sans-serif;

  color: #666666;
  margin-bottom: 10px;
}

.dropdownContainer {
  display: flex;
  align-items: center;
  border: 1px solid #66666659;
  border-radius: 5px;
  cursor: pointer;
  height:56px;
}

.selectedOptions {
  display: flex;
  align-items: center;
  flex: 1;
}

.option {
  display: flex;
  align-items: center;
  background-color: #e0e0e0;
  border-radius: 5px;
  height: 25px;
  font-size: 13px;
  font-weight: 600;
  padding: 1px 10px;
  margin-bottom: 3px;
  margin-left: 17px;
}

.option svg {
  margin-right: 14px;
  cursor: pointer;
}

.arrowContainer{
  height:100%;
   border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
  display:flex;
  align-items: center;
  justify-content: center;
}

.arrowIcon {
  
  margin-right: 24px;
}

.dropdownMenu {
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 4px;
  margin-top: 4px;
  z-index: 10;
  max-height: 260px;  
    overflow-y: auto;
}

.menuItem {
  padding: 8px;
  cursor: pointer;
}

.menuItem:hover {
  background-color: #f0f0f0;
}
.searchFeild{
  width: 100%;
  flex:2;
  border:none;
  height: 54px;
}
.searchFeild:focus{
  outline:none
}
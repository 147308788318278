.container {
  border-radius: 16px;
  background-color: white;
  margin-top: 45px;
  padding: 0 25px;

  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}
.containerOpen
{
  padding-bottom: 24px;
}
.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 24px;
  padding-bottom: 20px;
  padding-right: 24px;
  font-family: 'Public Sans', sans-serif;
  font-weight: 600;
  font-size: 22px;
  cursor: pointer;
}

.title {
  font-size: 22px;
  font-weight: bold;
  font-family: 'Public Sans', sans-serif;

}
.line {
  border: 1px solid #E3E3E3;
  width: 100%; 
  padding: 0; 
  margin-top: 20px;
}


.icons {
  display: flex;
  align-items: center;
}

.detailsIcon {
  margin-right: 35px;
}

.dropdownArrow {
  margin-right: 0px;

}



.tabs {
  display: flex;
  margin-top: -2px;
  font-family: 'Public Sans', sans-serif;
  font-size: 19px;
  font-weight: 600;
}

.tab {
  cursor: pointer;
  padding: 14px;
  margin-bottom: 30px;
  margin-right: 84px;
  box-sizing: border-box;
  color:  #8B8B8B;
}

.activeTab {
  font-weight: bold;
  color: #23272E;
  background-color: #0F60FF0A;
  border-top: 3px solid #00726B;

}

.filterContainer {
  margin-top: 20px;
}

.filterGroup {
  display: flex;
  flex-direction: column;
}

.content{
  display: flex;
  margin-bottom: 35px;
  justify-content: space-between;
  align-items: center;
  margin-top:45px
}

.filterLabel {
  font-weight: 400;
  font-size: 18px;
  color:#666666;
  font-family: 'Rubik', sans-serif;
}



.selectWrapper {
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  border: 1px solid rgba(102, 102, 102, 0.35);  
  height: 56px;
  border-radius: 5px;
  padding-right:26px ;

}


.fullWidth {
  width: 100%;
  height:55px;
  border-radius: 5px;
  font-weight: 400;
  font-size: 18px;
   font-family: 'Rubik', sans-serif;
}

.textField {
  border-radius: 5px;
  padding: 10px;
  border: 1px solid #ccc;
}


.spacer {
  height: 20px;
}

.paginationContainer {
  margin-top: 20px;
}

.tableContainer {
  margin-top: 20px;
  border: 1px solid #ddd;
  padding:24px;
  border-radius: 16px;
  display: grid;

}

.table {
  width: 100%;
  border-collapse: collapse;
  
}

.headerRow{
  display: grid;
  column-gap: 8px;
  grid-template-columns: repeat(6,1fr);
  width: 100%;
  border-bottom: 1px solid #ddd;
  padding: 16px 8px;
  background-color: rgba(248, 249, 250, 1);
  border-bottom: 1px solid #ddd;
}

.tableRow{
  display: grid;
  grid-template-columns: repeat(6,1fr);
  column-gap: 8px;
  border-bottom: 1px solid #ddd;
  padding:16px 0;
  padding-left: 8px;
}

.headerCell {
  display: flex;
  flex-direction: row;
  flex:1;
  font-weight: 500;
  font-size: 14px;
  color:#232321CC;
  font-family: 'Rubik', sans-serif;
}
.headerCell:hover {
  cursor: pointer;
}
.cell{
  display: flex;
  align-items: center;
}
.cell:hover{
  cursor: pointer;
}
.rankCell {
  font-weight: 600;
  font-size: 28px;
  font-family: 'Rubik', sans-serif;
  font-family: 'Open Sans', sans-serif;


}

.downloadsCell {
  
  color:#00726B;
}


.tableRow:hover {
  background-color: #f5f5f5;
}

.paginationContent {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
}

.tableArrowIcon{
  margin-right: 10px;
}

.imgCover{
  height: 64px;  
  width: 49px;
  border-radius: 5px;
}

.dropdown{
  flex: 100%;
}

.dropdownOptions{
  position:absolute;
  top:110%;
  background-color: white;
  width: 100%;
  max-height: 260px;
  z-index: 100 ;
  overflow-y: auto;
  border:1px  solid rgba(102, 102, 102, 0.35);
  border-radius: 8px;
  margin:0
}

.dropdownOption:hover{
  background-color: #f0f0f0;
}
.dropdownOption{
  padding: 10px 16px;
}

.searchInput{
  width:100%;
  outline: none;
  border:none;
  height: 100%;
}

.searchInput:focus{
  outline:none;
}
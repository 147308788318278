.container {
    width: 100%;
    background-color: #ffffff; 
    border-radius: 16px;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.07); 
    padding-top: 20px;
    padding-bottom: 20px;
    padding-left: 30px;
    padding-right: 30px;
    font-family: Open Sans, sans-serif;
}

.Title {
    color: #23272E;
    text-align: left;
    font-size: 18px;
    font-weight: 600;
    font-family: Public Sans, sans-serif;
}

.booksContainer {
    display: flex;
    gap:90px;
    margin-top: 20px;
}

.bookCard {
    width: 140px;

    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.bookCover {
    width: 100%;
    height: 180px;
    border-radius: 4px;

}

.bookTitle {
    font-size: 14px;
    height: 40px;
    color: #151833;
    text-align: left;
    width: 130px; 
    font-weight: 600;
    font-family: 'Poppins', sans-serif;
    margin-bottom: 15px;
    margin-top: 10px;
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
}
.bookgrades{
    display: flex;
    width:130px ;
    flex-wrap: wrap;
    gap:2px
}
.bookGrade {
    background-color: #00726B; 
    color: #ffffff; 

    padding: 3px 15px;
    border-radius: 5px;
    font-size: 9px;
    font-weight: 500;
    font-family: 'Poppins', sans-serif;
    width: auto;
 }

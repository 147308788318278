.background {
    background-color: white;
    padding: 44px 27px;
    margin-top: 35px;
    margin-right: 35px;
    margin-left: 8px;
    border-radius: 16px;
  }
  
  .Title {
    font-weight: 600;
    font-size: 20px;
    font-family: 'Public Sans', sans-serif;
    margin-bottom: 30px;
  }
  
  .form {
    display: flex;
    flex-direction: column;
  }
  
  .row {
    display: flex;
    justify-content: space-between;
    margin-bottom: 30px;
    gap:32px;
  }
  
  .field {
    flex: 1;
    margin-bottom: 23px;
    position: relative; /* Ensure the container can position its children absolutely */
  }
  
  .field:last-child {
    margin-right: 0;
  }
  
  .phoneContainer {
    display: flex;
  }
  
  .phoneCode {
    width: 13%;
    margin-right: 0px;
  border-radius: 5px 0 0 5px; 
  }
  
  .phoneNumber {
    width: 80%;
    border-radius: 0 5px 5px 0; 
  
  }
  
  .fullWidth {
    width: 100%;
  }
  
  .buttonContainer {
    display: flex;
    justify-content: flex-end;
    gap:32px
  }
  

  
  .DeleteButton{
    color:#BE1E2D;
    font-family: 'Rubik', sans-serif;
  font-size: 14px;
  font-weight: 500;
  background-color: #fff; 
  border: 0px solid #fff;
  
  }
  
  .ArchiveButton{
   color: #00726B;
   font-family: 'Rubik', sans-serif;
   font-size: 14px;
   font-weight: 500;
   background-color: #fff; 
   border: 2px solid #00726B;
   padding:8px, 16px, 8px, 16px;
   border-radius: 8px;
   width:141px;
   height:48px;
  }
  
  .AddButton{
    color: #fff;
   font-family: 'Rubik', sans-serif;
   font-size: 14px;
   font-weight: 500;
   background-color: #00726B; 
   border: 2px solid #00726B;
  
   padding:8px, 16px, 8px, 16px;
   border-radius: 8px;
   width:141px;

   height:48px;
   display: flex;
   align-items: center;
   justify-content: center;
  }



  .arrowIcon {
    position: absolute;
    right: 24px;  
    top: 50%;
    transform: translateY(-50%);
    pointer-events: none; 
  }
  
  input, textarea {
    height: 56px;
    font-size: 18px;
    font-family: 'Rubik', sans-serif;
  
    padding: 10px;
    border: 1px solid #66666659;
    border-radius: 5px;
    box-sizing: border-box;
  }
  
  .desctextField{
    width: 100%;
    height: 159px;
    font-size: 18px;
    font-family: 'poppins', sans-serif;
    font-weight: 500;
    padding: 10px;
    border: 1px solid #66666659;
    border-radius: 5px;
    box-sizing: border-box;
  }
  select {
    padding: 10px ;  
    -webkit-appearance: none; /* Chrome, Safari, Edge */
    -moz-appearance: none;    /* Firefox */
    appearance: none;         /* Standard */
      outline: none;
      border: none;
      padding-right: 30px;      /* Add space for the custom arrow */
    
  }
  
  label {
    display: block;
    margin-bottom: 10px;
    color: #666666;
    font-size: 18px;
    font-weight: 400;
    font-family: 'Rubik', sans-serif;
  }


  .textField{
    border: 1px solid #66666659;
    height: 56px;
    font-size: 18px;
    font-weight: 400;
    font-family: 'Rubik', sans-serif;
    width:100%
  }

  .ChangePasswordContainer{
    margin-top: 68px;
  }

  
  textarea {
    resize: vertical;
  
  }

  .selectWrapper {
    position: relative;
    
  }

 
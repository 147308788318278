.sidebar {
  width: 290px;
  background-color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 30px;
  border-right: 1px solid #fff;
  transition: width 0.3s;
  overflow-y: auto;
  overflow-x: hidden;  
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  box-sizing: border-box;
  font-family: Open Sans, sans-serif;
}

.logo {
  margin-bottom: 20px;
}

.sectionTitle {
  color: grey;
  font-size: 11px;
  margin: 20px 0 13px 0;
  width: 100%;
  text-align: left;
  padding-left: 10px;
}

.menuItem {
  display: flex;
  align-items: center;
  width: 100%;
  padding: 13px 15px;
  color: grey;
  font-size: 15px;
  text-decoration: none;
  cursor: pointer;
  transition: background-color 0.3s, color 0.3s;
}

.menuItem:hover {
  background-color: #F3F4F8;
  border-radius: 6px;
}

.menuItem.active {
  background-color: #F3F4F8;
  font-weight: bold;
  border-radius: 6px;
  color: #23272E;
}

.menuItem.active .icon {
  fill: none;
  stroke: #23272E;
}

.icon {
  margin-right: 10px;
  transition: stroke 0.3s;
}


.logout {
  margin-top: 68px;
  color: #BE1E2D;
}

.logout:hover {
  background-color: #F3F4F8;
}

/* Responsive Styles */
@media (max-width: 768px) {
  .sidebar {
      width: 180px;
      padding: 20px;
  }

  .menuItem {
      padding: 10px 12px;
      font-size: 14px;
  }

  .sectionTitle {
      font-size: 10px;
      padding-left: 8px;
  }
}

@media (max-width: 480px) {
  .sidebar {
      width: 150px;
      padding: 15px;
  }

  .menuItem {
      padding: 8px 10px;
      font-size: 13px;
  }

  .sectionTitle {
      font-size: 9px;
      padding-left: 6px;
  }

  .logo {
      margin-bottom: 15px;
  }
}

.container {
  margin-top: 50px
}

.content {
  margin-top: 50px;
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: 'space-between';
}
.content2{
  display: flex;
  align-items: center;
}

.content3{
  display: flex;
  align-items: center;
}
.spacer {
  width: 487px;
}
.spacer2 {
  width: 553px;
}
.spacer3 {
  width: 650px;
}
.paginationContainer{
  margin-top: 37px;

}
.buttonSpacer {
  width: 8px;
}
.link {
  text-decoration: none;
}


.container {
    background-color: #ffffff;
    border-radius: 16px;
    border: 1px solid #23232133;
    padding: 16px;
    margin-bottom: 30px;
    margin-top: 35px;
}
.spanStyle{
    display: -webkit-box; 
    -webkit-box-orient: vertical; 
    -webkit-line-clamp: 2;
    overflow: hidden;
    text-overflow: ellipsis; 
    word-break: break-word;
    overflow-wrap: break-word; 
    line-height: 1.5em; 
    max-height: 3em; 
    line-clamp: 2;
}

.table {
    width: 100%;
    border-collapse: collapse;
}

.tableHeader {
    display: flex;
    gap:16px;
    padding-left:8px;
    align-items: center;
    background-color: #f8f9fa;
    border-bottom: 1px solid #dfe3e8;
}

.tableHeaderCell {
    color: #232321cc;
    display: flex;
    font-weight: 500;
    font-size: 14px;
    padding: 18px 0px; 
    align-items: center;
    cursor: pointer;
    font-family: Rubik, sans-serif;
    flex: 1;
    white-space: nowrap;
}

.actions {
    position:absolute;
    right: -16px;
    text-align: right;
    font-weight: 500;
    color: #232321cc;
    padding: 0;
}
 
.tableRow {
    display: flex;
    align-items: center;
    gap:16px;
    padding-left:8px;
    font-size: 12px; 
    font-weight: 600;
    font-family: 'Open Sans', sans-serif;
}

.tableRow:hover{
    background-color: #f5f5f5;
    cursor:pointer
}

.tableCell {
    flex: 1; 
    position: relative;
    padding: 16px 0px; 
    color: black;
}

.tableCell:last-child {
    overflow: visible;
}

.rowSeparator {
    border: none;
    border-top: 0.5px solid #23232133;
    width: calc(100% - 10px);
}

.detailsIcon {
    cursor: pointer;
    fill: #4a90e2;
    margin-left: 30px;
    display: absolute;
    width: 32px;
}


.arrowIcon {
    margin-right: 10px; 
}

/* Dropdown Menu Styles */
.dropdownMenu {
    position: absolute;
    top: 40%;
    right:20px;
    background-color: #fff;
    border: 1px solid #E2E2E2;
    border-radius: 10px;
    box-shadow: 0 1px 4px rgba(0, 0, 0, 0.1);
    font-size: 12px;
    font-weight: 400;
    text-align: left;
    color: #212121;
    z-index: 100;
    font-family: Nunito, sans-serif;
    display: flex;
    flex-direction: column;
}

.dropdownItem {
    padding: 6px 20px;
    cursor: pointer;
    white-space: nowrap;
}
.dropdownItem:last-child{
    color: #DD112D;
}

.dropdownItem:hover {
    background-color: #f0f0f0;
}

.delete {
    color: #DD112D;
}

.content3 {
    display: flex;
    align-items: center;
    margin-right: 17px;
}



.coverImage{
    height: 64px;
    width:55px;
    border-radius: 5px;
}
    .content {
    margin-top: 50px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .content2{
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 35px;
    margin-top: 35px;
  }
  
  .content3{
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .link {
    text-decoration: none;
  }
  
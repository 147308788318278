.background {
    
    background-color: white;
    padding: 44px 0;
    margin-top: 35px;
    margin-right: 35px;
    margin-left: 8px;
    border-radius: 16px;
  }
  
  .Title {
    font-weight: 600;
    font-size: 20px;
    font-family: 'Public Sans', sans-serif;
    margin-bottom: 30px;
    padding:0px 27px

  }
  
  .form {
    display: flex;
    flex-direction: column;
  }
  
  .row {
    display: flex;
    justify-content: space-between;
    margin-bottom: 30px;
    gap:33px;
    padding:0px 27px
  }
  
  .field {
    flex: 1;
    width: 100%;
    
  }

  .rowSeparator {
    border: none;
    border-top: 0.5px solid #23232133;
    width: 100% ;
}
  
  
  .field2{
    flex: 1;
     margin-top: 30px;
  }
  
  
  
  .field:last-child {
    margin-right: 0;
  }
  
  
  
  .fullWidth {
    width: 100%;
    
  }
  
  .buttonContainer {
    display: flex;
    gap: 32px;
    justify-content: flex-end;
    margin-top: 25px;
    margin-right:23px;
  }
  

  
  .DeleteButton{
    color:#BE1E2D;
    font-family: 'Rubik', sans-serif;
  font-size: 14px;
  font-weight: 500;
  background-color: #fff; 
  border: 0px solid #fff;
  
  }
  
  .ArchiveButton{
   color: #00726B;
   font-family: 'Rubik', sans-serif;
   font-size: 14px;
   font-weight: 500;
   background-color: #fff; 
   border: 2px solid #00726B;
   padding:8px, 16px, 8px, 16px;
   border-radius: 8px;
   width:141px;
   height:48px;
  }
  
  .AddButton{
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
   font-family: 'Rubik', sans-serif;
   font-size: 14px;
   font-weight: 500;
   background-color: #00726B; 
   border: 2px solid #00726B;
  
   padding:8px, 16px, 8px, 16px;
   border-radius: 8px;
   width:141px;
   height:48px;
   display: flex;
   justify-content: center;
   align-items: center;
  }
  .arrowIcon {
     left: 30px;
    top: 50%;
    flex:1;
    transform: translateY(-50%);
    
  }
  
  input, textarea {
    height: 56px;
    font-size: 18px;
    font-family: 'Rubik', sans-serif;
    padding: 10px;
    border: 1px solid #66666659;
    border-radius: 5px;
    box-sizing: border-box;
  }
  .textField{
    width: 100%;
  }
  
  .desctextField{
    width: 100%;
    height: 159px;
    font-size: 18px;
    font-family: 'poppins', sans-serif;
    font-weight: 500;
    padding: 10px;
    border: 1px solid #66666659;
    border-radius: 5px;
    box-sizing: border-box;
  }
  select {
    padding-right: 10px;  
  }
  
  label {
    display: block;
    margin-bottom: 10px;
    color: #666666;
    font-size: 18px;
    font-weight: 400;
    font-family: 'Rubik', sans-serif;
  }
  
  textarea {
    resize: vertical;
  
  }
  
  .hr{
    margin-bottom:24px;
    height: 2px;
  }
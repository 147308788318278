  
  .content {
    margin-top: 50px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .content2{
    display: flex;
    margin-bottom: 37px;
    justify-content: space-between;
    margin-top: 50px;
    align-items: center;
  }
  
  .addressDescriptionContainer{
    display: flex;
    align-items: center;
  }
  .content3{
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .paginationContainer{
    margin-top: 37px;

  }
  .buttonSpacer {
    width: 8px;
  }
  .link {
    text-decoration: none;
  }
  
.container {
    background-color: #ffffff;
    border-radius: 16px;
    padding: 16px;
    margin-bottom: 53px;
}

.table {
    width: 100%;
    border-collapse: collapse;
}

.tableHeader {
    display: grid;
    align-items: center;
    column-gap: 16px;
    background-color: #f8f9fa;
    border-bottom: 1px solid #dfe3e8;
    padding-left: 8px;
    padding-right: 16px;
    
}

.tableHeaderCell {
    color: #232321cc;
    display: flex;
    font-weight: 500;
    font-size: 14px;
    padding: 18px 0px; 
    align-items: center;
    justify-content: left;
    cursor: pointer;
    font-family: Rubik, sans-serif;
    flex: 1;
    white-space: nowrap;
    overflow: hidden;
}

.actions {
    text-align: center;
    font-weight: 500;
    color: #232321cc;
    position: relative;
}
 
.tableRow {
    display: grid;
    align-items: center;
    font-size: 12px; 
    font-weight: 600;
    font-family: 'Open Sans', sans-serif;
    justify-content: space-between;
    column-gap: 16px;
    padding-left: 8px;
    padding-right: 16px;
    border-bottom: 0.5px solid #23232133;;
}
.tableRow:hover {
    background-color: #f9f9f9;
    cursor: pointer;
}




.tableCell {
    flex:1;
    padding: 16px 0px; 
    color: black;
    display: flex;
    align-items: center;
    justify-content: left;
    white-space: wrap;
}

.detailsIcon {
    cursor: pointer;
    fill: #4a90e2;
    position: absolute;
    right: -32px;
    top: 50%;
    transform: translateY(-50%);
    width: 32px;
}

.arrowIcon {
    margin-right: 10px; 
}

/* Dropdown Menu Styles */
.dropdownMenu {
    position: absolute;
    width: 108px;
    top: 40%;
    right: -10px;
    background-color: #fff;
    border: 1px solid #E2E2E2;
    border-radius: 10px;
    box-shadow: 0 1px 4px rgba(0, 0, 0, 0.1);
    font-size: 12px;
    font-weight: 400;
    align-items: left;
    text-align: left;
    color:#212121;
    z-index: 100;
    font-family: Nunito, sans-serif;
    display: flex;
    flex-direction: column;
}

.dropdownItem {
    padding: 6px 20px;
    cursor: pointer;
    white-space: nowrap;
}

.dropdownItem:hover {
    background-color: #f0f0f0;
}

.delete {
    color: #DD112D;
}

.content3{
    display: flex;
    align-items: center;
    margin-right: 17px;
  }

 .spacer3 {
    width: 600px;
  }

  .tableCell:last-child {
    white-space: nowrap ;
}



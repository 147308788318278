.background {
    background-color: white;
    padding: 44px 27px;
    border-radius: 16px;
  }
  
  .Title {
    font-weight: 600;
    font-size: 20px;
    font-family: 'Public Sans', sans-serif;
    margin-bottom: 30px;
  }
  
  .form {
    display: flex;
    flex-direction: column;
  }
  
  .row {
    display: flex;
    justify-content: space-between;
    padding-left: 3px;
  }
  .row:not(:last-of-type){
    margin-bottom: 30px;
  }
  
  .field {
    flex: 1;
    margin-right: 33px;
    width:20px;
  }
  
  .field:last-child {
    margin-right: 0;
  }
  
  .phoneContainer {
    display: flex;
  }
  
  .phoneCode {
    width: 13%;
    margin-right: 0px;
  border-radius: 5px 0 0 5px; 
  }
  
  .phoneNumber {
    width: 80%;
    border-radius: 0 5px 5px 0; 
  
  }
  
  .fullWidth {
    width: 100%;
  }
  
  .buttonContainer {
    display: flex;
    gap:32px;
    justify-content: flex-end;
    margin-top: 75px;
  }
  

  .DeleteButton{
    color:#BE1E2D;
    font-family: 'Rubik', sans-serif;
  font-size: 14px;
  font-weight: 500;
  background-color: #fff; 
  border: 0px solid #fff;
  
  }
  
  .ArchiveButton{
   color: #00726B;
   font-family: 'Rubik', sans-serif;
   font-size: 14px;
   font-weight: 500;
   background-color: #fff; 
   border: 2px solid #00726B;
   padding:8px, 16px, 8px, 16px;
   border-radius: 8px;
   width:141px;
   height:48px;
  }
  
  .AddButton{
    color: #fff;
   font-family: 'Rubik', sans-serif;
   font-size: 14px;
   font-weight: 500;
   background-color: #00726B; 
   border: 2px solid #00726B;
  
   padding:8px, 16px, 8px, 16px;
   border-radius: 8px;
   width:141px;
   height:48px;
   display: flex;
   justify-content: center;
   align-items: center;
  }
  .arrowIcon {
     left: 30px;
    top: 50%;
    flex:1;
    transform: translateY(-50%);
    
  }
  
  input, textarea {
    height: 56px;
    font-size: 18px;
    font-family: 'Rubik', sans-serif;
  
    padding: 10px;
    border: 1px solid #66666659;
    border-radius: 5px;
    box-sizing: border-box;
  }
  
  .desctextField{
    width: 100%;
    height: 159px;
    font-size: 18px;
    font-family: 'poppins', sans-serif;
    font-weight: 500;
    padding: 10px;
    border: 1px solid #66666659;
    border-radius: 5px;
    box-sizing: border-box;
  }
  select {
    padding-right: 10px;  
  }
  .textfield{
    width: 309px;
    height: 56px;
    font-size: 18px;
  }
  
  label {
    display: block;
    margin-bottom: 10px;
    color: #666666;
    font-size: 18px;
    font-weight: 400;
    font-family: 'Rubik', sans-serif;
  }
  
  textarea {
    resize: vertical;
  
  }
.container{
    display:flex;
    align-items:center;
    justify-content:center;
    height: 100%;
}

.image{
    width:200px;
    height:200px;
    margin-bottom:20px;
}

.header{
    font-weight: bolder;
    font-size: 20px;
    color:red;
}

.body{
    height: 150px;
    display: flex;
    flex-direction: column;
    justify-content:space-around;
}
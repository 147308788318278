.dropdownContainer {
    display: flex;
    flex-direction: column;
  }
  
  .label {
    color: #666666;
    font-size: 18px;
    font-weight: 400;
    font-family: 'Rubik', sans-serif;
    margin-bottom: 10px;
  }
  
  .dropdown {
    position: relative;
    display: flex;
    width: 100%;
    height: 56px;
    align-items: center;
    justify-content: space-between;
    border: 1px solid #66666659;
    border-radius: 5px;
    background-color: #ffffff;
    cursor: pointer;
  }
  
  .selectedOption {
    color: #000000;
    margin-left: 17px;
    font-size: 18px;
    font-weight: 400;
    font-family: 'Rubik', sans-serif;
  }
  
.arrowContainer{
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
}

  .arrowIcon {
   margin-right: 23px;

  }
  
  .options {
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    margin-top: 8px;
    border: 1px solid #e0e0e0;
    border-radius: 4px;
    background-color: #ffffff;
    z-index: 100;
    list-style: none;
    padding: 0;
    max-height: 260px;  
    overflow-y: auto;
  }
  
  .option {
    padding: 10px 16px;
    cursor: pointer;
  }
  
  .option:hover {
    background-color: #f0f0f0;
  }

  .searchInput{
    width: 100%;
    border: none;
    margin: 3px 0; 
    height: 100%;
  }
  .searchInput:focus{
    outline: none;
  }
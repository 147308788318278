.background {
  background-color: white;
  padding: 44px 27px;
  margin-top: 35px;
  margin-right: 35px;
  margin-left: 8px;
  border-radius: 16px;
}

.Title {
  font-weight: 600;
  font-size: 20px;
  font-family: 'Public Sans', sans-serif;
  margin-bottom: 30px;
}

.form {
  display: flex;
  flex-direction: column;
}

.row {
  display: flex;
  justify-content: space-between;
  margin-bottom: 30px;
}

.field {
  width: 100%;
  margin-right: 33px;
}

.field2{
  width: 100%;
  margin-top: 30px;
}

.phoneNumberInputContainer{
  display: flex;
}


.field:last-child {
  margin-right: 0;
}

.phoneContainer {
  display: flex;
  width: 100%;
}

.phoneCode {
  width: 13%;
  margin-right: 0px;
  border-radius: 5px 0 0 5px; 
  border-right: none;
}
.phoneCode:focus{
  outline: none;
}

.phoneNumber {
  width: 100% !important;
  border-radius: 0 5px 5px 0; 
  flex:1
}

.fullWidth {
  width: 100%;
}



.buttonContainer {
  display: flex;
  justify-content: flex-end;
  margin-top: 47px;
  gap:32px;
}

.DeleteButton{
  color:#BE1E2D;
  font-family: 'Rubik', sans-serif;
font-size: 14px;
font-weight: 500;
background-color: #fff; 
border: 0px solid #fff;

}

.ArchiveButton{
 color: #00726B;
 font-family: 'Rubik', sans-serif;
 font-size: 14px;
 font-weight: 500;
 background-color: #fff; 
 border: 2px solid #00726B;
 padding:8px, 16px, 8px, 16px;
 border-radius: 8px;
 width:141px;
 height:48px;
}

.AddButton{
  color: #fff;
  font-family: 'Rubik', sans-serif;
  font-size: 14px;
  font-weight: 500;
  background-color: #00726B; 
  border: 2px solid #00726B;
  padding:8px, 16px, 8px, 16px;
  border-radius: 8px;
  width:141px;
  height:48px;
  display: flex;
  justify-content: center;
  align-items: center ;
}
.arrowIcon {
   left: 30px;
  top: 50%;
  flex:1;
  transform: translateY(-50%);
  
}

input, textarea {
  height: 56px;
  font-size: 18px;
  font-family: 'Rubik', sans-serif;

  padding: 10px;
  border: 1px solid #66666659;
  border-radius: 5px;
  box-sizing: border-box;
}

.desctextField{
  width: 100%;
  height: 159px;
  font-size: 18px;
  font-family: 'poppins', sans-serif;
  font-weight: 500;
  padding: 10px;
  border: 1px solid #66666659;
  border-radius: 5px;
  margin-bottom: 30px;
}

.textField { 
  width: 100%;
}

.addressTextField { 
  width: 100%;
  height: 56px;
  font-size: 18px;
  font-family: 'poppins', sans-serif;
  font-weight: 500;
  padding: 10px;
  border: 1px solid #66666659;
  border-radius: 5px;
  box-sizing: border-box;
}

.phoneTextField { 
  width: 82%;
}

.textFieldCode { 
  width: 95%;
}

select {
  padding-right: 10px;  
}

label {
  display: block;
  margin-bottom: 10px;
  color: #666666;
  font-size: 18px;
  font-weight: 400;
  font-family: 'Rubik', sans-serif;
}

textarea {
  resize: vertical;

}

.imageContainer {
  width: 100%;  
  height: 130px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  
}

.previewImage {
  object-fit:scale-down;
  border-radius: 5px; 
  flex:1
}

.addImageContainer{
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 30px;
}

.addImageSection{
  border:1px dashed black;
  display: flex;
  flex-direction: column;
  align-items: center;  
  justify-content: center;
  border-radius: 8px;
  padding: 16px;
  height: 150px;
  margin-top:20px
}
 
.addImageSectionContainer{
  flex: 1;
}

.addImageSectionContainer >h1{
  color: #181818;
  font-weight: 600;
  font-size: 20px;
}

.uploadButton{
  margin: 0; 
  background-color: #00726B;
  color: white;
  font-weight: 500;
  padding: 8px 40px;
  border-radius: 5px;
}
.addImageParag{
  margin: 16px 0;
  color: #70706E;
  font-weight: 600;
}

.previewImage {
  width: 100%; 
  height: 70%; 
  object-fit: contain; 
  border-radius: 5px; 
  margin-bottom: 8px;
}
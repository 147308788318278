
.pagination  {
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: 'Public Sans', sans-serif;
  gap:8px;
}

.iconButton, .pageButton {
  background: #fff;
  border: none;
  cursor: pointer;
  padding: '4px, 8px, 4px, 8px';
  height: 28px;
  width: 28px;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 400;
  font-size: 13px;
  color: #8B909A;
  transition: background-color 0.3s ease;
}

.iconButton:disabled, .pageButton:disabled {
  cursor: not-allowed;
}

.pageButton:hover:not(.active), .iconButton:hover:not(:disabled) {
  background-color: #f0f0f0;
}

.active {
  background-color: #005855;
  color: white;
}

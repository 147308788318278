.container {
    display: flex;
    flex-direction: column;
  }
  
  .label {
    margin-bottom: 5px;
    font-size: 18px;
    font-family: 'Rubik', sans-serif;
  }
  
  .textField {
    height: 56px;
    font-size: 18px;
    font-family: 'Rubik', sans-serif;
    padding: 10px;
    border: 1px solid #66666659;
    border-radius: 5px;
    box-sizing: border-box;
  }
  
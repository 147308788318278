.container {
  background-color: #ffffff;
  border-radius: 16px;
  /* overflow: hidden; */
  border: 1px solid #23232133;
  padding: 16px;
  margin-bottom: 53px;
}

.table {
  width: 100%;
  border-collapse: collapse;
}

.tableHeader {
  display: flex;
  gap:8px;
  align-items: center;
  background-color: #f8f9fa;
  border-bottom: 1px solid #dfe3e8;
  padding: 16px 0;
  padding-left:8px;
}

.tableHeaderCell {
  display: flex;
  align-items: start;
  color: #232321cc;
  font-weight: 500;
  font-size: 14px;
  align-items: start;
  cursor: pointer;
  font-family: Rubik, sans-serif;
  /* margin-left: 30px; */
  flex: 1;
  white-space: nowrap;
}

.actions {
  text-align: center;
  position: relative;
}



.tableRow {
  display: flex;
  gap:8px;
  align-items: center;
  font-size: 12px;
  font-weight: 600; 
  font-family: 'Open Sans', sans-serif;
  padding: 28px 0; 
  padding-left:8px;
  border-bottom: 1px solid #e0e0e0; 
}

.tableRow:hover{
  background-color: #f0f0f0;
  cursor: pointer;

}

.tableCell {
  display: flex;  
  justify-content: space-between;
  flex: 1;
  
  color: black;
  white-space: nowrap;
  
  align-items:center;
}


.detailsIcon {
  cursor: pointer;
  fill: #4a90e2;
  width: 30px;
}

.arrowIcon {
  margin-right: 10px;
}

.dropdownMenu {
  position: absolute;
  width: 108px;
  top: 30%;
  right: 16px;  
  background-color: #fff;
  border: 1px solid #e2e2e2;
  border-radius: 10px;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.1);
  font-size: 12px;
  font-weight: 400;
  text-align: left;
  color: #212121;
  z-index: 100;
  font-family: Nunito, sans-serif;
  display: flex;
  flex-direction: column;
}

.dropdownItem {
  padding: 10px 15px 5px 15px;
  cursor: pointer;
  white-space: nowrap;
}

.dropdownItem:hover {
  background-color: #f0f0f0;
}

.delete {
  color: #dd112d;
}

.coverImageContainer{
  border: 1px solid rgba(176, 176, 176, 1);
  width: 241px;
  height: 121px;
  padding: 6px 12px;
  border-radius: 4px;

}
.coverImage{
  width: 100%;
  height: 100%;
  object-fit:contain;
  border-radius: 8px;
}
.nodata{
  width: 100%;
  text-align: center;
  padding-top:16px;
}

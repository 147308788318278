
  .content {
    margin-top: 50px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .content2{
    display: flex;
    align-items: center;
    margin-bottom: 35px;
    justify-content: space-between;
    align-items: center;
    margin-top: 37px;
  }
  
  .content3{
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .spacer {
    width: 487px;
  }
  .spacer2 {
    width: 553px;
  }
  .spacer3 {
    width: 650px;
  }
  .paginationContainer{
    margin-top: 37px;

  }
  .buttonSpacer {
    width: 8px;
  }
  .link {
    text-decoration: none;
  }
  
  .ModificationsText{
    font-size: 14px;
    font-weight: 500;
    font-family: 'Rubik', sans-serif;
    cursor: pointer;

    text-decoration: underline;
    color:#00726B;
    margin-right: 28px;


  }
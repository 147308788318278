.container {
    width: 100%;
    background-color: #ffffff; 
    border-radius: 16px;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.07); 
    overflow: hidden;
    padding:12px;
    font-family: Open Sans, sans-serif;
    font-weight: 600; 

}

.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 15px;
    font-family: Public Sans, sans-serif;
    font-weight: 600;
    background-color: #fff; 
    font-size: 18px;

 }

.icon {
    cursor: pointer;
}

.thinLine {
    border: none;
    border-top: 0.5px solid #23232133; 
    margin: 0;
    margin-bottom: 16px;
}


.table {
    width: 100%;
    border-collapse: collapse;
    margin: 0;
}

th, td {
    text-align: left;
     font-weight: 600;


}

th {
    background-color: #F8F9FA; 
    color: #232321CC;
    padding:10px;
    font-family: Rubik, sans-serif;
    font-size: 16px;
    padding-left: 24px;


}
td{
    font-size: 14px;
    padding-top: 35px;
    padding-bottom: 19px;
    padding-left: 23.5px;

}
.booksOwned {
    padding-left: 65px; /* Add padding to the "Books Owned" column */
}

.separator td {
    padding: 0;
}

.loadingtr{
 

    
}

.loadingtd{
    width: 100%;
    text-align: center ;
}

.tableRow:hover{
    background-color: #f0f0f0;
    cursor: pointer;
}
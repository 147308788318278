/* AdminAccounts.module.css */
.container {
    
}

.content {
    margin-top: 50px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.content2 {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom:35px;
    margin-top:35px;
}

.content3 {
    display: flex;
    align-items: center;
    justify-content: center;
}

.paginationContainer {
    margin-top: 37px;
}

.buttonSpacer {
    width: 8px;
}

.link {
    text-decoration: none;
}

.superAdmin {
    background-color: #00726B;
    color: white;
    font-family: 'Public Sans', sans-serif;
font-size: 13px;
font-weight: 600;
    padding-top: 5px;
    padding-bottom: 5px;
    padding-right: 10px;
    padding-left: 10px;
    border-radius: 5px;
}

.editor {
    background-color: #BE1E2D;
    padding: 5px 10px;
    border-radius: 5px;
    color: white;
    font-family: 'Public Sans', sans-serif;
    font-size: 13px;
    font-weight: 600;
}

.sales {
    background-color: #0076A9;
    padding-top: 5px;
    padding-bottom: 5px;
    padding-right: 10px;
    padding-left: 10px;
    border-radius: 4px;
    color: white;
    font-family: 'Public Sans', sans-serif;
    font-size: 13px;
    font-weight: 600;
}
